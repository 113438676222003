/* eslint-disable @typescript-eslint/naming-convention */
// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
	SITE_NAME: 'KosherDat',
	production: false,
	AppVersion: '1.1.8',
	/* WEBurl: 'https://crazyshidduchim.webarysites.com/',
	APIurl: 'http://localhost/crazy_shidduchim/api/', */
	WEBurl: 'https://beta.kosherdat.com/',
	APIurl: 'https://beta.system.kosherdat.com/api/',
	APIAccessToken: 'KosHeRDat2K22iDeaTe',
	defaultErrorTitle: 'Oops!',
	defaultErrorMsg: 'Something went wrong!',
	defaultAuthErrorTitle: 'Access Denied!',
	defaultAuthErrorMessage: 'Please login to continue.',
	defaultAccessibilityErrorTitle: 'Access Denied!',
	defaultAccessibilityErrorMessage: 'You don\'t have an access to this feature.',
	defaultNoResultErrorMsg: 'No result found!',
	deviceType: 'browser',
	navLevels: [0, -1, -1],
	HttpRequestTimeout: 60000, // In milliseconds
	FirebaseVapidKey: 'BPvnPdc2opEaZI9tiryi0H481rWcynbPnK5N3A3m7R-4KyRqR8ZnFbuNzwaDlo1h9QNAbsaSqFKrKVBrlRFg1jM',
	firebaseConfig: {
		apiKey: 'AIzaSyDMQsKhXvh5U0ODBhc08Inw1pFL390D6Ng',
		authDomain: 'kosherdat.firebaseapp.com',
		projectId: 'kosherdat',
		storageBucket: 'kosherdat.appspot.com',
		messagingSenderId: '314633947645',
		appId: '1:314633947645:web:fcf2ef87e192ffd5fb7194',
		measurementId: 'G-FRGVJQ1F3H'
	}
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
