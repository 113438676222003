import { Component, Input, OnInit } from '@angular/core';
import { Account } from 'src/app/providers/account';
import { IdeateProvider } from 'src/app/providers/ideateProvider';
import { UtilsService } from 'src/app/services/utils.service';
import swal from 'sweetalert2';
import { ModalController } from '@ionic/angular';

@Component({
	selector: 'app-report-user',
	templateUrl: './report-user.component.html',
	styleUrls: ['./report-user.component.scss'],
})
export class ReportUserComponent implements OnInit {

	@Input() cardUserId: number;
	@Input() userName: string;

	public reportMessage = '';

	constructor(
		private ideateProvider: IdeateProvider,
		private account: Account,
		private utilsService: UtilsService,
		public modalController: ModalController,
	) { }

	ngOnInit() { }

	ionViewDidEnter(){
		this.reportMessage = '';
	}

	reportOnChange(event: any){
		this.reportMessage  = event.target.value;
	}

	async reportUser(){
		return new Promise(async resolve => {

			const requestParams: any = {};
			requestParams.user_id = this.account.userDetails.id;
			requestParams.auth_token = this.account.userDetails.auth_token;

			requestParams.card_user_id = this.cardUserId;
			requestParams.note = this.reportMessage;

			await this.utilsService.show();
			this.ideateProvider.makeHttpRequest('users/report_user', requestParams).then(async (result) => {
				await this.utilsService.hide();
				if (result.success === 1) {
					console.log('reportUser --> reportUser success');
					this.modalController.dismiss();
					swal.fire({
						icon: 'success',
						text: 'We have received a report regarding the user and appropriate action will be taken.',
						backdrop: false
					});
					return resolve(true);
				} else if (result.error === 1) {
					if(result.errorCode === 201 || result.errorCode === 104){
						this.ideateProvider.toast('', 'User not found.');
						this.ideateProvider.navigate('tabs/home');
					}else if(result.errorCode === 105){
						this.ideateProvider.toast('', 'You have already reported to this user.');
					}else {
						this.ideateProvider.toast('', this.ideateProvider.config.defaultErrorMsg);
					}
					return resolve(false);
				} else {
					this.ideateProvider.toast('', 'API_ERROR : ' + this.ideateProvider.config.defaultErrorMsg);
					return resolve(false);
				}
			}).catch(async (httpError) => {
				await this.utilsService.hide();
				this.ideateProvider.toast('', 'CONNECTIVITY_ERROR : ' + httpError.errorMessage);
				return resolve(false);
			});
		});
	}


}
