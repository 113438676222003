/* eslint-disable eqeqeq */
/* eslint-disable max-len */
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { SplashScreen } from '@capacitor/splash-screen';
import { StatusBar, Style } from '@capacitor/status-bar';

import { IdeateProvider } from './providers/ideateProvider';
import { Account } from './providers/account';

// message service provider
import { MessagesService } from './services/messages.service';
import swal from 'sweetalert2';

import { initializeApp as fireBaseIni } from 'firebase/app';
import { getMessaging, onMessage } from 'firebase/messaging';

import { ActionPerformed, PushNotificationSchema, PushNotifications } from '@capacitor/push-notifications';

@Component({
	selector: 'app-root',
	templateUrl: 'app.component.html',
	styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {

	constructor(
		public ideateProvider: IdeateProvider,
		public messagesService: MessagesService,
		private account: Account,
	) {
		console.log('App --> constructor');


		let tabInactiveCounter = 0;
		let taInactiveCounterInterval = null;
		window.addEventListener('focus', (event) => {
			console.warn('Inactive --> Active counter: ', tabInactiveCounter);
			clearInterval(taInactiveCounterInterval);
			if(tabInactiveCounter > 3){
				if(this.account.userDetails !== '' && JSON.stringify(this.account.userDetails) !== '{}'){
					// get app counters notification counter and unread message counter
					this.messagesService.getAppCounters();
					if(this.ideateProvider.currentUrl === '/tabs/checkout'){
						this.ideateProvider.navigate('tabs/home');
					} else if(this.ideateProvider.currentUrl === '/tabs/chat'){
						if(this.messagesService.roomId > 0){
							this.messagesService.getUserMessages(1, 'new', true, false);
						}
						this.messagesService.loadChatUserList(1, false);
					}
				}
			}
		});

		window.addEventListener('blur', (event) => {
			tabInactiveCounter = 0;
			console.warn('Active --> Inactive counter: ');
			taInactiveCounterInterval = setInterval(() => {
				tabInactiveCounter++;
			}, 1000);
		});

	}

	async ngOnInit() {
		// remove dark mode
		const prefersDark = window.matchMedia('(prefers-color-scheme: light)');
		document.body.classList.remove('dark');
		/* const prefersLight = window.matchMedia('(prefers-color-scheme: light)');
		this.ideateProvider.isDarkMode = prefersLight.matches; */
		console.log('App --> ngOnInit');
		await SplashScreen.show();

		this.initializeApp();
		this.firebaseIniWithPlatform();

		setTimeout(() => {
			if(this.ideateProvider.updateAvailable && this.ideateProvider.updateAvailable.urgencyLevel === 0){
				swal.fire({
					icon: 'info',
					allowEscapeKey: true,
					showCloseButton: true,
					title: 'Update Available',
					backdrop: false,
					text: 'A new update is available for your app. Please upgrade for a better experience.',
					confirmButtonText: '<a class="modal_link" target="_blank" href="'+this.ideateProvider.updateAvailable.link+'">Update</a>',
				});
			}
		}, 5000);

	}

	async firebaseIniWithPlatform(){

		try{

			/* const getDeliveredNotifications = async () => {
				const notificationList = await PushNotifications.getDeliveredNotifications();
				console.log('delivered notifications', notificationList);
			}; */

			// firebase notification implement with device type
			if(this.ideateProvider.config.deviceType === 'browser'){ // browser devices

				// Initialize Firebase
				const firebase = fireBaseIni(this.ideateProvider.config.firebaseConfig);
				const requestingPermissionPromise = await this.ideateProvider.requestForNotificationPermission();
				const messaging = getMessaging(firebase);
				console.log('firebaseIniWithPlatform messaging -> ', messaging);

				onMessage(messaging, async (payload) => {
					console.log('Notification received. Browser', payload);
					this.notificationReceive(payload);
				});

			}else{ // android or ios device
				// Show us the notification payload if the app is open on our device
				PushNotifications.addListener('pushNotificationReceived',
					async (notification: PushNotificationSchema) => {
						console.log('notification receive during app open', notification);
						this.notificationReceive(notification);
					}
				);

				// Method called when tapping on a notification
				PushNotifications.addListener('pushNotificationActionPerformed',
					(notification: ActionPerformed) => {
						console.log('clicked on notification', notification);
						const notificationData = notification.notification.data;
						if(notificationData.type == 'request'){
							if(notificationData.request_url == 'load_messages'){
								this.messagesService.currentRoomInfo = null;
								this.messagesService.roomId = notificationData.room_id;
								console.log(this.ideateProvider.currentUrl);
								this.ideateProvider.navigate('/tabs/chat', {roomId: this.messagesService.roomId});
							} else if(notificationData.request_url == 'get_notification_count'){
								this.ideateProvider.navigate('/tabs/notifications');
							}
						}
					}
				);
			}

		}catch(err){
			console.log('An error occurred while initialization firebase.', err);
		}
	}

	async notificationReceive(notification){
		const notificationData = notification.data;
		if(notificationData.type === 'request'){
			if(notificationData.request_url === 'load_messages'){
				// compare notification data with current page data
				if(this.ideateProvider.currentUrl === '/tabs/chat'){
					if(this.messagesService.roomId.toString() === notificationData.room_id.toString()){
						this.messagesService.getUserMessages(1, 'new', true, false);
						await this.messagesService.getAppCounters();
					}else{
						if(this.messagesService.roomId != 0 || this.messagesService.roomId.toString() !== notificationData.room_id.toString()){
							const browserNotificationData: any = JSON.parse(notificationData.showNotificationData);
							if(this.ideateProvider.config.deviceType !== 'browser'){
								this.ideateProvider.toast('', browserNotificationData.body, 2000, '', 'top');
							}
						}
						this.messagesService.chatUserListIsLastRecord = false;
						this.messagesService.loadChatUserList(1, false);
					}
				}else{ // in any case need to update the unread counter of the message
					// get app counters notification counter and unread message counter
					await this.messagesService.getAppCounters();
					// this.ideateProvider.playNotificationSound();
					if(notificationData.showNotification){
						const browserNotificationData: any = JSON.parse(notificationData.showNotificationData);
						this.ideateProvider.showBrowserNotification(browserNotificationData.title, browserNotificationData.body);
					}
				}
			}else if(notificationData.request_url === 'get_notification_count'){
				// get app counters notification counter and unread message counter
				await this.messagesService.getAppCounters();
				if(notificationData.showNotification){
					const browserNotificationData: any = JSON.parse(notificationData.showNotificationData);
					this.ideateProvider.showBrowserNotification(browserNotificationData.title, browserNotificationData.body);
				}
			}else{}

		} else if (notificationData.type === 'update'){
			if(notificationData.update_action === 'delete_message'){
				// compare notification data with current page data
				if((this.ideateProvider.currentUrl === '/tabs/chat' && this.messagesService.roomId.toString() === notificationData.room_id.toString())
				){
					console.warn('deleting the message...');
					this.messagesService.userMessages.map((v,i)=>{
						if(v.message_id === notificationData.action_message_id){
							if(notificationData.action_taken_by === 'admin'){
								this.messagesService.userMessages[i].chat_messages_text = this.messagesService.adminDeleteMsgPlaceholder;
							}else{
								this.messagesService.userMessages[i].chat_messages_text = this.messagesService.deleteMsgPlaceholder;
							}
							this.messagesService.userMessages[i].deletedMsg = 1;
							this.messagesService.userMessages[i].awaitingApprove = 0;
						}
					});
				}
			} else if(notificationData.update_action === 'approve_message' || notificationData.update_action === 'undo_message'){
				// compare notification data with current page data
				if((this.ideateProvider.currentUrl === '/tabs/chat' && this.messagesService.roomId.toString() === notificationData.room_id.toString())){
					console.warn(notificationData.update_action+' the message...');
					this.messagesService.userMessages.map((v,i)=>{
						if(v.message_id === notificationData.action_message_id){
							const messageData: any = JSON.parse(notificationData.action_message_data);
							console.log('messageData', messageData);
							console.log(this.messagesService.userMessages[i]);
							this.messagesService.userMessages[i].attachments = messageData.attachments;
							this.messagesService.userMessages[i].chat_messages_text = messageData.message;
							if(messageData.attachments.length > 0){
								this.messagesService.userMessages[i].awaitingApprove = 0;
							}
							if(notificationData.update_action === 'undo_message'){
								this.messagesService.userMessages[i].awaitingApprove = messageData.awaiting_approval;
								if(messageData.awaiting_approval === 1){
									this.messagesService.userMessages[i].chat_messages_text = this.messagesService.awaitingMsgPlaceholder;
								}
								this.messagesService.userMessages[i].deletedMsg = 0;
							}
							console.log(this.messagesService.userMessages[i]);
						}
					});
				}
			} else if(notificationData.update_action === 'active_account'){
				this.ideateProvider.toast('','Account activated.');
				this.account.userDetails.status = 'active';
				this.account.completeCheckout = true;
				await this.ideateProvider.storage.set('userDetails', this.account.userDetails);
				if(((this.ideateProvider.currentUrl === '/tabs/checkout' || this.ideateProvider.currentUrl === '/tabs/edit-profile'
						|| this.ideateProvider.currentUrl === '/tabs/settings') && this.account.userDetails.id === notificationData.userId)){
					this.ideateProvider.navigate('tabs/home');
				}
			}
		}
	}

	initializeApp() {
		console.log('App --> initializeApp');
		this.ideateProvider.platform.ready().then(async () => {

			let page = '';
			const pageSegments = window.location.pathname.split('/');
			if (pageSegments.length > 0) {
				if (pageSegments[0] === '') {
					pageSegments.shift();
				}
				if (pageSegments.length > 0) {
					page = pageSegments[0];
				}
			}

			this.account.initialize().then(async () => {
				await StatusBar.setStyle({ style: Style.Default }).catch(() => { });
				await SplashScreen.hide();
				if (this.account.userDetails && this.account.userDetails.auth_token) {
					this.account.verifyAuthToken(this.account.userDetails.id, this.account.userDetails.auth_token)
					.then(async (result: any) => {
						if (result.success === 1) {
							// this.account.userDetails = result.data.userDetails;
							// await this.ideateProvider.storage.set('userDetails', this.account.userDetails);
							if (['/', '/login', '/login/reset-password'].indexOf(this.ideateProvider.router.url) >= 0) {

								if(this.account.userDetails.payment_status === 'paid'){
									this.ideateProvider.navigate('tabs/home');
								}else{
									this.ideateProvider.navigate('tabs/checkout');
								}

							}
						} else {
							if(!(['signUp']).includes(page)){
								this.ideateProvider.storage.remove('userDetails');
								this.ideateProvider.navigateWithNavControl('/login');
							}
						}
					}).catch(() => {
							if(!(['signUp']).includes(page)){
								this.ideateProvider.storage.remove('userDetails');
								this.ideateProvider.navigateWithNavControl('/login');
							}
					});
				} else {
					if(page.search('login-as;') >= 0){
						page = 'login-as';
					}
					if(!(['signUp', 'login', 'terms-and-condition', 'login-as']).includes(page)){
						this.ideateProvider.storage.remove('userDetails');
						// this.ideateProvider.navigate('/home');
						this.ideateProvider.navigateWithNavControl('/login');
					}
				}
			}).catch(async () => {
				this.ideateProvider.storage.remove('userDetails');
				// this.ideateProvider.navigate('/home');
				this.ideateProvider.navigateWithNavControl('/login');
			});
		});

	}


}
