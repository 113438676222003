import { Injectable, Output, EventEmitter } from '@angular/core';
import { IdeateProvider } from './ideateProvider';
import { environment } from './../../environments/environment';
import { UtilsService } from '../services/utils.service';
// import { MessagesService } from '../services/messages.service';

@Injectable()
export class Account {

	@Output() evOnLogOut = new EventEmitter();

	public config = environment;

	public userDetails: any = {};
	public cntUnreadThreads = 0;
	public adminUsers: any = [];
	public blockedUsers: any = []; // this variable used in setting page

	public userMessage: any = {}; // this variable user for the welcome back the user when inactive user is login back

	public completeCheckout = false;

	constructor(
		private ideateProvider: IdeateProvider,
		private utilsService: UtilsService
		) {
			console.log('account constructor');
		this.initialize();
	}

	async initialize() {
		return new Promise((resolve, reject) => {
			this.ideateProvider.platform.ready().then(() => {
				this.ideateProvider.storage.get('userDetails').then((userDetails) => {
					if (userDetails && userDetails.auth_token) {
						this.userDetails = userDetails;
					}
					resolve(true);
				}).catch(error => {
					resolve(true);
				});
			});
		});
	}

	async verifyAuthToken(userID: number, authToken: string) {
		console.log('Account --> verifyAuthToken');
		return new Promise((resolve, reject) => {
			const reqParams: any = {};
			reqParams.user_id = userID;
			reqParams.auth_token = authToken;
			reqParams.app_version = this.config.AppVersion;
			this.ideateProvider.makeHttpRequest('account/validate_auth_token', reqParams).then((response) => {
				if (response.success === 1) {
					resolve(response);
				} else {
					reject(false);
				}
			}).catch((httpError) => {
				reject(false);
			});
		});
	}

	getUserInfoLatest(){
		return new Promise(resolve => {
			const requestParams: any = {};
			requestParams.user_id = this.userDetails.id;
			requestParams.auth_token = this.userDetails.auth_token;
			this.ideateProvider.makeHttpRequest('account/get_user_info', requestParams).then(async (result) => {
				if (result.success === 1) {
					this.ideateProvider.storage.remove('userDetails');
					this.ideateProvider.storage.set('userDetails', result.data.userDetails);
					this.userDetails = result.data.userDetails;
					resolve(true);
				}else{
					resolve(false);
					this.ideateProvider.storage.remove('userDetails');
					this.userDetails = {};
					this.evOnLogOut.emit();
					this.ideateProvider.navigateWithNavControl('/login');
				}
			}).catch(async (httpError) => {
				resolve(false);
				this.ideateProvider.storage.remove('userDetails');
				this.userDetails = {};
				this.evOnLogOut.emit();
				this.ideateProvider.navigateWithNavControl('/login');
			});
		});
	}

	async doLogout() {
		if (!this.userDetails || (this.userDetails && !this.userDetails.auth_token)) {
			this.userDetails = await this.ideateProvider.storage.get('userDetails');
		}
		if (this.userDetails.auth_token && this.userDetails.id) {
			await this.utilsService.show();
			const requestParams: any = {};
			requestParams.user_id = this.userDetails.id;
			requestParams.auth_token = this.userDetails.auth_token;
			this.ideateProvider.makeHttpRequest('account/logout', requestParams).then(async (result) => {
				this.ideateProvider.storage.remove('userDetails');
				this.ideateProvider.storage.remove('notificationCount');
				this.ideateProvider.storage.remove('countryCode');
				this.userDetails = {};
				this.evOnLogOut.emit();
				await this.utilsService.hide();
				this.ideateProvider.navigateWithNavControl('/login');
			}).catch(async (httpError) => {
				this.ideateProvider.storage.remove('userDetails');
				this.userDetails = {};
				this.evOnLogOut.emit();
				await this.utilsService.hide();
				this.ideateProvider.navigateWithNavControl('/login');
			});
		} else {
			this.ideateProvider.navigateWithNavControl('/login');
		}
	}

	async updateFCMToken(){
		console.log('Account --> updateFCMToken --> set FCM token');
		if (!this.userDetails || (this.userDetails && !this.userDetails.auth_token)) {
			this.userDetails = await this.ideateProvider.storage.get('userDetails');
		}
		if (this.userDetails != null && this.userDetails !== undefined && this.userDetails.auth_token && this.userDetails.id) {
			this.ideateProvider.storage.get('FCMToken').then(fcmToken => {

				const requestParams: any = {};
				requestParams.user_id = this.userDetails.id;
				requestParams.auth_token = this.userDetails.auth_token;
				requestParams.device_fcm_token = fcmToken;

				this.ideateProvider.makeHttpRequest('account/update_fcm_token', requestParams).then(async (result) => {
					console.log('FCM Token Updated...');
				}).catch(async (httpError) => {
					console.warn('error while update the fcm token ', httpError);
				});
			});
		}
	}

	async blockUser(cardUserId){
		return new Promise(async resolve => {

			const requestParams: any = {};
			requestParams.user_id = this.userDetails.id;
			requestParams.auth_token = this.userDetails.auth_token;

			requestParams.card_user_id = cardUserId;

			await this.utilsService.show();
			this.ideateProvider.makeHttpRequest('users/block_user', requestParams).then(async (result) => {
				await this.utilsService.hide();
				return resolve(result);
			}).catch(async (httpError) => {
				await this.utilsService.hide();
				this.ideateProvider.toast('', 'CONNECTIVITY_ERROR : ' + httpError.errorMessage);
				return resolve(false);
			});
		});
	}

}
