/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable eqeqeq */
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable, ApplicationRef } from '@angular/core';
import { observable, Observable, Subject } from 'rxjs';
import { Account } from '../providers/account';
import { IdeateProvider } from '../providers/ideateProvider';
import { UtilsService } from './utils.service';

@Injectable({
	providedIn: 'root'
})
export class MessagesService {

	// chat user list
	public chatUserListIsLastRecord = false;
	public chatUserListInfoMessage: string;
	public chatUserList: any = [];
	public chatUserListPage = 1;
	public chatUserListEOL: boolean;

	public chatListInterval: any;
	public appNotificationMessageCounterInterval: any;

	// badge counter
	public notificationCounter = 0;
	public unreadMsgCount = 0;

	// for navigate the chat-list to inbox
	public currentRoomInfo: any;

	// inbox page
	public messageUserId = 0;
	public roomId = 0;

	public userMessageInfoMessage: string;
	public userMessages: any = [];
	public userMessagesListPage = 1;
	public isLastMessage = false;

	public loadMessageProcessing = false;
	public scrollMessagePage = false;

	public messageText: any = '';

	// scrollPage use for the scroll the page when new message received
	public scrollContentSubject = new Subject();
	public scrollContent$ = this.scrollContentSubject.asObservable();

	public chatBodyHeight: any;
	public chatBodyCurrentScrollLocation: any;
	public scrollbarAtBottom: any;

	// deleted message placeholder
	public deleteMsgPlaceholder = 'This message was deleted';
	public adminDeleteMsgPlaceholder = 'This message was deleted by admin';
	public awaitingMsgPlaceholder = 'Restricted content awaiting for approval';

	public sendMessageInprogress = false;
	public sendMessageInprogressTimeout: any;

	// user online status
	public onlineStatus: any = {
		last_seen_at: '',
		online_status: false,
		name: '',
		opposite_block_status: '',
		current_user_block_status: '',
	};

	// message read unread
	public roomReadUnreadStatus: any = {
		is_group: 0,
		last_read_msg_id: 0,
		group_read: [],
		login_user_last_msg_read_id: 0
	};

	// message Attachment
	public msgAttachmentAllowedTypes = ['image/jpeg', 'jpeg', 'image/jpg', 'jpg', 'image/png', 'png', 'video/mp4', 'mp4', 'video/quicktime', 'quicktime', 'audio/mpeg', 'mpeg', 'audio/wav', 'wav', 'application/pdf', 'pdf', 'aac'];
	public msgAttachmentFile: any = '';

	// small device handle
	public isSmallDevice = false;
	public showConversionBodySection = false;

	// temp message array for instant sending msg
	public userTempMessages: any = [];

	// search value for the chatList
	public chatListSearchString = '';

	// fix temp message not remove issue
	private userTempMessageInterval: any = '';

	private userLogoutSubscriber;

	constructor(
		public app: ApplicationRef,
		public ideateProvider: IdeateProvider,
		public utilsService: UtilsService,
		public account: Account,
	) {
		console.log('MessagesService --> constructor');

		console.log(window.innerWidth);
		if(window.innerWidth < 950){
			this.isSmallDevice = true;
		}else{
			this.isSmallDevice = false;
		}

		this.userLogoutSubscriber = this.account.evOnLogOut.subscribe(() =>{
			console.log('user logout...');
			console.log('User Logout event emitted');
			clearInterval(this.chatListInterval);
			clearInterval(this.userTempMessageInterval);
			clearInterval(this.appNotificationMessageCounterInterval);
		});

	}

	// get chat list users
	loadChatUserList(page: number, showLoader = true, searchString: string = ''){
		console.log('MessagesService --> loadChatUserList');
		return new Promise(async resolve => {

			// limit reached
			if(this.chatUserListIsLastRecord){
				console.log('isLastRecord in chatUserList', this.chatUserListIsLastRecord);
				// this.chatUserList = [];
				return resolve(true);
			}

			const requestParams: any = {};

			requestParams.user_id = this.account.userDetails.id;
			requestParams.auth_token = this.account.userDetails.auth_token;
			requestParams.searchString = searchString;

			requestParams.page = page;

			if(showLoader){
				await this.utilsService.show();
			}

			if (requestParams.page === 1) {
				this.chatUserListEOL = false;
				this.chatUserListPage = 1;
			}

			this.ideateProvider.makeHttpRequest('messages/get_user_chat_list', requestParams).then(async (result) => {
				if(showLoader){
					await this.utilsService.hide();
				}
				if (result.success === 1) {
					console.log('MessagesService --> loadChatUserList success');

					this.chatUserListIsLastRecord = result.data.last_record;
					if(result.data.chatUserList.length > 0){

						if(requestParams.page === 1){
							this.chatUserList = result.data.chatUserList;
						}else{
							this.chatUserList = [...this.chatUserList, ...result.data.chatUserList];
						}

						if(result.data.last_record){
							this.chatUserListIsLastRecord = this.chatUserListEOL = true;
							this.chatUserListInfoMessage = 'That\'s all we have for now!';
						}

						if (requestParams.page <= 1 && result.data.chatUserList.length === 0) {
							this.chatUserListInfoMessage = ''; //Sorry! No users available. showing image here
							this.chatUserListEOL = true;
						}
					}else{ // messages list user not found
						if(requestParams.page === 1){
							this.chatUserList = result.data.chatUserList;
						}
					}

					await this.getAppCounters();
					return resolve(true);
				} else if (result.error === 1) {

					this.chatUserListEOL = true;
					this.chatUserListInfoMessage = '';

					this.ideateProvider.toast('', this.ideateProvider.config.defaultErrorMsg);
				} else {
					this.ideateProvider.toast('', 'API_ERROR : ' + this.ideateProvider.config.defaultErrorMsg);
				}
				return resolve(false);
			}).catch(async (httpError) => {
				if(showLoader){
					await this.utilsService.hide();
				}
				this.ideateProvider.toast('', 'CONNECTIVITY_ERROR : ' + httpError.errorMessage);
				return resolve(false);
			});
		});
	}


	// fetch messages
	// *** Params ***
	// page: indicate the pagination
	// getMessageType: wether to get all message or newly added message, possible values: 'all', 'new'
	// updateRedStatus: update read status or not
	// showLoader: showing loader when message loading or not

	getUserMessages(page: number, getMessageType = 'all', updateReadStatus = false, showLoader = false, scrollAction = 'none'){
		return new Promise(async resolve => {
			console.log('MessagesService --> getUserMessages', 'page',page, 'getMessageType',getMessageType, 'updateReadStatus',updateReadStatus, 'showLoader',showLoader, 'scrollAction',scrollAction, 'loadMessageProcessing', this.loadMessageProcessing);

			if(!this.loadMessageProcessing){
				this.loadMessageProcessing = true;

				const requestParams: any = {};
				requestParams.user_id = this.account.userDetails.id;
				requestParams.auth_token = this.account.userDetails.auth_token;

				// requestParams.message_user_id = this.messageUserId
				requestParams.room_id = this.roomId;

				requestParams.fetch_message_type = getMessageType;

				requestParams.page = page;
				let last_msg_id = 0;
				let first_msg_id = 0;

				requestParams.page = page;
				if(scrollAction === 'up'){
					last_msg_id = this.userMessages[0].message_id;
					first_msg_id = 0;
				}else if(scrollAction === 'down'){
					first_msg_id = this.userMessages[(this.userMessages.length - 1)].message_id;
					last_msg_id = 0;
				}

				if(getMessageType === 'new'){
					first_msg_id = this.userMessages[(this.userMessages.length - 1)].message_id;
				}

				requestParams.last_msg_id = last_msg_id;
				requestParams.first_msg_id = first_msg_id;

				requestParams.updateReadStatus = updateReadStatus;

				if (requestParams.page === 1 && getMessageType === 'all') {
					// this.userMessages = [];
					this.isLastMessage = false;
					this.userMessagesListPage = 1;
				}

				if(this.currentRoomInfo === undefined || this.currentRoomInfo == null){
					requestParams.user_info_requested = true;
				}else{
					requestParams.user_info_requested = false;
				}

				if(showLoader){
					await this.utilsService.show();
				}

				this.ideateProvider.makeHttpRequest('messages/load_messages', requestParams).then(async (result) => {
					this.loadMessageProcessing = false;
					if(showLoader){
						await this.utilsService.hide();
					}
					if (result.success === 1) {
						console.log('MessagesService --> getUserMessages success');

						if(requestParams.user_info_requested){
							this.currentRoomInfo = result.data.currentRoomInfo;
						}

						// last message read unread ids
						this.roomReadUnreadStatus = result.data.msg_read_status;
						console.log('roomReadUnreadStatus', this.roomReadUnreadStatus);

						// update read status for old messages
						/* if(updateReadStatus){
							this.chatUserList.map((v,i)=>{
								if(v.room_id == this.roomId){
									this.chatUserList[i].notification_count = 0;
								}
							});
						} */

						/* if(last_msg_id > 0){
							if(result.data.msg_read_status == 1){
								this.userMessages.map((v,i)=>{
									if(v.message_id <= result.data.message_id){
										this.userMessages[i].read_status = 1;
									}
								});
							}
						} */

						if(getMessageType === 'new'){
							if(result.data.msg_read_status.is_group === 0){ // we need only update read status for the single user for now, we will improve it later on
								this.userMessages.map((v,i)=>{
									if(v.message_id <= result.data.msg_read_status.last_read_msg_id){
										this.userMessages[i].read_status = 1;
									}
								});
							}
						}

						this.userMessagesListPage = requestParams.page;
						if(requestParams.page === 1){
							if(getMessageType === 'all'){
								if(result.data.isGroup){
									this.currentRoomInfo.isGroup = true;
								}else{
									this.currentRoomInfo.isGroup = false;
								}
								this.userMessages = result.data.messages;
							}else{
								this.userMessages = [...this.userMessages, ...result.data.messages];
							}
						}else{
							this.userMessages = [...result.data.messages, ...this.userMessages];
						}

						if(getMessageType === 'all'){
							this.isLastMessage = result.data.isLastMessage; // load more msg flag
						}

						if(result.data.messages.length > 0){
							this.scrollMessagePage = true;

							// remove message from the temp message
							result.data.messages.map((v,i)=>{
								this.userTempMessages.map((temp_message, temp_message_index)=>{
									if(temp_message.messageId == v.message_id){
										this.userTempMessages.splice(temp_message_index, 1);
									}
								});
							});

							if(this.userTempMessageInterval != ''){
								clearInterval(this.userTempMessageInterval);
							}
							this.userTempMessageInterval = setInterval(()=>{
								this.userTempMessages.map((temp_message, temp_message_index)=>{
									this.userMessages.map((v,i)=>{
										if(temp_message.messageId == v.message_id){
											this.userTempMessages.splice(temp_message_index, 1);
										}
									});
								});
							}, 5000);

							// update the latest message in chat list for this room
							this.updateLatestMessageInChatList();
						}else{
							this.scrollMessagePage = false;
						}

						// chat page is loaded and scroll to bottom && read the the previous messages status 1
						console.log('userMessagesListPage',this.userMessagesListPage, 'getMessageType', getMessageType, 'result.data.messages.length',result.data.messages.length);
						if(this.userMessagesListPage === 1 && getMessageType === 'new' && result.data.messages.length > 0){
							/* if(last_msg_id > 0){
								this.userMessages.map((v,i)=>{
									if(v.message_direction === 'right' && v.message_id < last_msg_id && v.read_status == 0){
										this.userMessages[i].read_status = 1;
									}
								});
							} */
							console.log('need to scroll at new message');
							setTimeout(() => {
								this.scrollContentSubject.next();
							}, 500);
						}

						// user last seen information
						this.onlineStatus = result.data.userOnlineStatus;

						setTimeout(()=>{
							this.app.tick();
						});
						return resolve(true);
					} else if (result.error === 1) {
						if(result.errorCode === 201){
							this.ideateProvider.toast('', 'User not found.');
							this.ideateProvider.navigate('tabs/home');
						}else if(result.errorCode === 105){
							// this.ideateProvider.toast('', "User not found.");
							console.warn('redirect to chatList page because user not found.');
							this.ideateProvider.navigate('tabs/chat');
						}else{
							this.ideateProvider.toast('', this.ideateProvider.config.defaultErrorMsg);
						}
					} else {
						this.ideateProvider.toast('', 'API_ERROR : ' + this.ideateProvider.config.defaultErrorMsg);
					}
				}).catch(async (httpError) => {
					this.loadMessageProcessing = false;
					if(showLoader){
						await this.utilsService.hide();
					}
					this.ideateProvider.toast('', 'CONNECTIVITY_ERROR : ' + httpError);
				});
			}
		});
	}

	updateLatestMessageInChatList(){
		console.log('MessagesServices -> updateLatestMessageInChatList');
		const lastMessageOfRoom = this.userMessages[(this.userMessages.length - 1)];
		console.log('lastMessageOfRoom', lastMessageOfRoom);
		let lastMessage = '';
		if(lastMessageOfRoom.awaitingApprove === 1){
			lastMessage = this.awaitingMsgPlaceholder;
		} else if (lastMessageOfRoom.deletedMsg === 1){
			lastMessage = this.deleteMsgPlaceholder;
		} else if (lastMessageOfRoom.attachments.length > 0){
			lastMessage = lastMessageOfRoom.attachments[0].name;
		} else {
			if(lastMessageOfRoom.chat_messages_text.length > 15){
				lastMessage = lastMessageOfRoom.chat_messages_text.substr(0, 15) + '...';
			}else{
				lastMessage = lastMessageOfRoom.chat_messages_text;
			}
		}
		this.chatUserList.map((v,i)=>{
			if(this.roomId === v.room_id){
				this.chatUserList[i].last_message = lastMessage;
				this.chatUserList[i].last_message_time = lastMessageOfRoom.timeInterval;
				return false;
			}
		});
	}

	// to main the time ago for the each messages
	updateMessageTimeStamp(increment = 0){

		console.log('MessagesService --> updateMessageTimeStamp');

		const templates = {
			prefix: '',
			suffix: ' ago',
			seconds: '%d second',
			minute: '%d minute',
			minutes: '%d minutes',
			hour: '%d hour',
			hours: '%d hours',
			day: '%d day',
			days: '%d days',
			month: '%d month',
			months: '%d months',
			year: '%d year',
			years: '%d years'
		};

		const template = (t, n) => templates[t] && templates[t].replace(/%d/i, Math.abs(Math.round(n)));

		const timer = (time) => {
			if(!time)
				{return;}
			time = new Date(time * 1000);
			const now = new Date();
			// eslint-disable-next-line no-bitwise
			const seconds = ((now.getTime() - time) * .001) >> 0;
			const minutes = seconds / 60;
			const hours = minutes / 60;
			const days = hours / 24;
			const years = days / 365;
			return templates.prefix + (
				seconds < 45 && template('seconds', seconds) ||
				seconds < 90 && template('minute', 1) ||
				minutes < 45 && template('minutes', minutes) ||
				minutes < 90 && template('hour', 1) ||
				hours < 24 && template('hours', hours) ||
				hours < 42 && template('day', 1) ||
				days < 30 && template('days', days) ||
				days < 45 && template('month', 1) ||
				days < 365 && template('months', days / 30) ||
				years < 1.5 && template('year', 1) ||
				template('years', years)
				) + templates.suffix;
		};

		this.userMessages.map((v,i)=>{
			if(v.is_system_msg === 0){
				// eslint-disable-next-line radix
				let messageTimeStamp = parseInt(v.timestamp);
				messageTimeStamp += increment;
				const msgTimeAgo = timer(messageTimeStamp);
				this.userMessages[i].timeInterval = (msgTimeAgo === '0 second ago') ? 'few second ago' : msgTimeAgo;
			}
		});

	}

	// send message
	async sendMessage(){
		if(!this.sendMessageInprogress){

			clearTimeout(this.sendMessageInprogressTimeout);
			this.sendMessageInprogressTimeout = setTimeout(() => {
				this.sendMessageInprogress = true;
			}, 5000);

			const key = this.ideateProvider.timestamp() + this.ideateProvider.randomNumber(1111,9999);
			let chat_messages_text = '';
			let isMediaFile = 0;
			if(this.msgAttachmentFile !== '' && this.messageText === ''){
				chat_messages_text = 'Uploading media file';
				isMediaFile = 1;
			}else{
				chat_messages_text = this.messageText;
				isMediaFile = 0;
			}
			this.userTempMessages.push(
				{
					key,
					chat_messages_text,
					messageId: 0,
					isMediaFile
				}
			);
			this.scrollContentSubject.next();

			return new Promise(resolve => {
				console.log('MessagesService --> sendMessage');

				const requestParams: any = {};
				requestParams.user_id = this.account.userDetails.id;
				requestParams.auth_token = this.account.userDetails.auth_token;

				requestParams.message_user_id = this.messageUserId;
				requestParams.room_id = this.roomId;

				requestParams.messageText = this.messageText;

				requestParams.attachmentFile = this.msgAttachmentFile;

				// key for the instant message
				requestParams.key = key;

				this.ideateProvider.makeHttpRequest('messages/send_message', requestParams).then(async (result) => {
					this.sendMessageInprogress = false;
					clearTimeout(this.sendMessageInprogressTimeout);
					if (result.success === 1) {

						this.userTempMessages.map((v,i)=>{
							if(v.key == result.data.key){
								this.userTempMessages[i].messageId = result.data.messageId;
							}
						});

						// clear attachment  && msgText
						this.msgAttachmentFile = '';
						this.messageText = '';

						console.log('InboxPage --> sendMessage success');
						await this.getUserMessages(1, 'new', true, false, 'down');
						setTimeout(()=>{
							this.app.tick();
						});
						return resolve(true);
					} else if (result.error === 1) {
						if(result.errorCode === 201){
							this.ideateProvider.toast('', 'User not found.');
							this.ideateProvider.navigate('tabs/home');
						}else if(result.errorCode === 108){
							this.ideateProvider.toast('Message Send Failed!', result.message);
						}else if(result.errorCode === 109 || result.errorCode === 110){
							// 109 ==> login user blocked by opposite user
							// 110 ==> login user has blocked this user
							this.ideateProvider.toast('Message Send Failed!','');
							this.userTempMessages = this.userTempMessages.filter(v => +v.key !== +result.data.key);
							await this.getUserMessages(1, 'new', true, false, 'down');
						}else{
							this.ideateProvider.toast('', this.ideateProvider.config.defaultErrorMsg);
						}
					} else {
						this.ideateProvider.toast('', 'API_ERROR : ' + this.ideateProvider.config.defaultErrorMsg);
					}
				}).catch(async (httpError) => {
					await this.utilsService.hide();
					this.ideateProvider.toast('', 'CONNECTIVITY_ERROR : ' + httpError.errorMessage);
				});
			});
		}

	}

	getAppCounters(){
		return new Promise( async resolve => {
			if (!this.account.userDetails || (this.account.userDetails && !this.account.userDetails.auth_token)) {
				this.account.userDetails = await this.ideateProvider.storage.get('userDetails');
			}
			if (this.account.userDetails.auth_token && this.account.userDetails.id) {
				const requestParams: any = {};
				requestParams.user_id = this.account.userDetails.id;
				requestParams.auth_token = this.account.userDetails.auth_token;

				this.ideateProvider.makeHttpRequest('common/get_app_counters', requestParams).then(async (result) => {
					if(result.success === 1){

						// notification counter
						this.ideateProvider.storage.get('notificationCount').then((count) =>{
							if(count != null && count !== 0 && parseInt(result.data.notificationCount, 10) > count){
								this.ideateProvider.playNotificationSound();
							}
							this.notificationCounter = parseInt(result.data.notificationCount, 10);
							this.ideateProvider.storage.set('notificationCount', this.notificationCounter);
						}).catch(()=>{
							this.notificationCounter = parseInt(result.data.notificationCount, 10);
							this.ideateProvider.storage.set('notificationCount', this.notificationCounter);
							console.log('Notification count not set.');
						});

						// message unread counter
						console.log('Account --> getAppCounters success');
						if(this.unreadMsgCount !== 0 && this.unreadMsgCount < result.data.unreadMsgCount){
							this.ideateProvider.playNotificationSound();
							if(this.ideateProvider.currentUrl === '/tabs/chat' && this.ideateProvider.fcmToken === ''){
								this.loadChatUserList(1, false);
							}
						}
						this.unreadMsgCount = parseInt(result.data.unreadMsgCount, 10);
						console.log('this.unreadMsgCount',this.unreadMsgCount);
						setTimeout(()=>{
							this.app.tick();
						});
						return resolve(true);
					}
				}).catch(async (httpError) => {
					console.warn('error while update the fcm token ', httpError);
					return resolve(false);
				});
			}else{
				return resolve(false);
			}
		});
	}

	// get unread message count
	/* getUnreadMessageCount(){
		console.log("MessagesService --> getUnreadMessageCount");
		if(this.chatUserList.length > 0){
			this.account.unreadMsgCount = 0;
			this.chatUserList.map((v,i)=>{
				this.account.unreadMsgCount += v.notification_count;
			});
		}else{
			if(this.account.unreadMsgCount === 0){
				const requestParams: any = {};
				requestParams.user_id = this.account.userDetails.id;
				requestParams.auth_token = this.account.userDetails.auth_token;
				this.ideateProvider.makeHttpRequest('messages/get_unread_message_count', requestParams).then(async (result) => {
					if (result.success === 1) {
						console.log("MessagesService --> getUnreadMessageCount success");
						this.account.unreadMsgCount = result.data.unreadMsgCount;
					} else if (result.error === 1) {
						this.ideateProvider.toast('', this.ideateProvider.config.defaultErrorMsg);
					} else {
						this.ideateProvider.toast('', 'API_ERROR : ' + this.ideateProvider.config.defaultErrorMsg);
					}
				}).catch(async (httpError) => {
					this.ideateProvider.toast('', 'CONNECTIVITY_ERROR : ' + httpError.errorMessage);
				});
			}
		}
	}*/

	// delete the message
	deleteMessage(message, action){
		return new Promise(resolve => {
			console.log('MessagesService --> deleteMessage');

			const requestParams: any = {};
			requestParams.user_id = this.account.userDetails.id;
			requestParams.auth_token = this.account.userDetails.auth_token;

			requestParams.room_id = this.roomId;
			requestParams.message_id = message.message_id;
			requestParams.message_user_id = this.messageUserId;
			requestParams.action = action;

			this.ideateProvider.makeHttpRequest('messages/message_action', requestParams).then(async (result) => {
				if (result.success === 1) {
					console.log('InboxPage --> message_action success');
					console.log(result);

					this.userMessages.map((v,i)=>{
						if(v.message_id == message.message_id){
							if(action == 'delete'){
								this.userMessages[i].chat_messages_text = this.deleteMsgPlaceholder;
								this.userMessages[i].awaitingApprove = 0;
								this.userMessages[i].deletedMsg = 1;
								this.userMessages[i].attachments = [];
								this.userMessages[i].undo_option_show = 1;
							}else{
								const messageActionData = result.data.message_action_data;
								if(messageActionData.deletedMsg == 1){
									this.userMessages[i].chat_messages_text = this.deleteMsgPlaceholder;
								}else if(messageActionData.awaitingApprove == 1){
									this.userMessages[i].chat_messages_text = this.awaitingMsgPlaceholder;
								}else{
									this.userMessages[i].chat_messages_text = messageActionData.message;
								}
								this.userMessages[i].awaitingApprove = messageActionData.awaitingApprove;
								this.userMessages[i].deletedMsg = messageActionData.deletedMsg;
								this.userMessages[i].attachments = messageActionData.attachments;
								this.userMessages[i].undo_option_show = messageActionData.undo_option_show;
							}
						}
					});

					this.updateLatestMessageInChatList();
					return resolve(true);
				} else if (result.error === 1) {
					if(result.errorCode === 201){
						this.ideateProvider.toast('', 'User not found.');
						this.ideateProvider.navigate('tabs/home');
					}else{
						this.ideateProvider.toast('', this.ideateProvider.config.defaultErrorMsg);
					}
				} else {
					this.ideateProvider.toast('', 'API_ERROR : ' + this.ideateProvider.config.defaultErrorMsg);
				}
			}).catch(async (httpError) => {
				await this.utilsService.hide();
				this.ideateProvider.toast('', 'CONNECTIVITY_ERROR : ' + httpError.errorMessage);
			});
		});
	}

	// init conversion with user
	initConversionWithUser(user, showLoader = false){
		console.log('MessagesService --> initConversionWithUser', user);
		return new Promise(async resolve => {

			const requestParams: any = {};

			requestParams.user_id = this.account.userDetails.id;
			requestParams.auth_token = this.account.userDetails.auth_token;
			requestParams.message_user_id = user.user_id;

			if(showLoader){
				await this.utilsService.show();
			}
			this.ideateProvider.makeHttpRequest('messages/initialize_conversion', requestParams).then(async (result) => {
				if(showLoader){
					await this.utilsService.hide();
				}
				if (result.success === 1) {
					console.log('MessagesService --> initConversionWithUser success');
					return resolve(result.data);
				} else if (result.error === 1) {
					if(result.errorCode === 109){
						// 109 ==> login user blocked by opposite user
						this.ideateProvider.toast('Message Send Failed!','');
					} else if(result.errorCode === 110){
						// 110 ==> login user has blocked this user
						this.ideateProvider.toast('','You\'ve Blocked this user. Unblock to send message.', false);
					}
					resolve(false);
					// this.ideateProvider.toast('', this.ideateProvider.config.defaultErrorMsg);
				} else {
					resolve(false);
					// this.ideateProvider.toast('', 'API_ERROR : ' + this.ideateProvider.config.defaultErrorMsg);
				}
			}).catch(async (httpError) => {
				if(showLoader){
					await this.utilsService.hide();
				}
				this.ideateProvider.toast('', 'CONNECTIVITY_ERROR : ' + httpError.errorMessage);
			});
		});
	}

}
