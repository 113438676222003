import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UtilsService } from '../../services/utils.service';
import { IdeateProvider } from '../../providers/ideateProvider';
import { Account } from '../../providers/account';
import { MessagesService } from '../../services/messages.service';
import swal from 'sweetalert2';
import { ActionSheetController, IonModal, ModalController } from '@ionic/angular';
import { ReportUserComponent } from 'src/app/components/report-user/report-user.component';

@Component({
	selector: 'app-info',
	templateUrl: './info.page.html',
	styleUrls: ['./info.page.scss'],
})
export class InfoPage implements OnInit {

	@Input() cardUserId: number;

	@ViewChild(IonModal) imgModal: IonModal;
	public modalImgSrc: string;

	public cardUserInfo: any;

	public userChoice = '';

	public userPlaceHolderImage = '';

	constructor(
		private ideateProvider: IdeateProvider,
		private account: Account,
		private utilsService: UtilsService,
		private messagesService: MessagesService,
		public modalController: ModalController,
		private actionSheetController: ActionSheetController,
	) {
		console.log('InfoPage --> constructor');

		const userGender = this.account.userDetails.gender;
		if(userGender === 'female'){
			this.userPlaceHolderImage = 'assets/male_placeholder.jpg';
		}else{
			this.userPlaceHolderImage = 'assets/female_placeholder.jpg';
		}

	}

	async ionViewDidLeave(){
		// check if loader is showing then hide it
		const loader = document.getElementById('ion-overlay-1');
		if(loader){
			await this.utilsService.hide();
		}
	}

	async ngOnInit() {
		console.log('InfoPage --> ngOnInit');

		if(this.cardUserId > 0){
			await this.getUserInfo(this.cardUserId);
		}else{
			console.log('user id is not valid');
			this.ideateProvider.navigate('tabs/home');
		}
	}

	closeModal(){
		this.modalController.dismiss();
	}

	// store user choice to the database
	userClickedButton(choice) {
		console.log('InfoPage --> userClickedButton');

		const requestParams: any = {};

		requestParams.user_id = this.account.userDetails.id;
		requestParams.auth_token = this.account.userDetails.auth_token;

		requestParams.choice = choice;
		requestParams.choiceUserId = this.cardUserInfo.user_id;

		this.ideateProvider.makeHttpRequest('matchmaking/user_made_choice', requestParams).then((result) => {

			if (result.success === 1) {
				console.log('InfoPage --> userClickedButton success');
				this.userChoice = result.data.userChoice;

			} else if (result.error === 1) {
				this.ideateProvider.toast('', this.ideateProvider.config.defaultErrorMsg);
			} else {
				this.ideateProvider.toast('', 'API_ERROR : ' + this.ideateProvider.config.defaultErrorMsg);
			}
		}).catch(async (httpError) => {
			this.ideateProvider.toast('', 'CONNECTIVITY_ERROR : ' + httpError.errorMessage);
		});

	}

	gotoMessage(user) {
		this.messagesService.initConversionWithUser(user, false).then((resultData: any) => {
			if(resultData){
				console.log('successfully --> ',resultData);
				// this.messagesService.currentRoomInfo = null;

				this.messagesService.roomId = resultData.room_id;
				this.ideateProvider.navigate('tabs/chat');
				this.modalController.dismiss();

			}else{
				swal.fire({
					icon: 'error',
					title: 'Oops...',
					backdrop: false,
					text: 'Fail to Initialize the conversion with the user. Please try again later.',
				});
			}
		});
	}

	getUserInfo(cardUserId: number){
		return new Promise(async resolve => {

			const requestParams: any = {};
			requestParams.user_id = this.account.userDetails.id;
			requestParams.auth_token = this.account.userDetails.auth_token;

			requestParams.card_user_id = this.cardUserId;

			await this.utilsService.show();
			this.ideateProvider.makeHttpRequest('users/get_user_info', requestParams).then(async (result) => {
				await this.utilsService.hide();
				if (result.success === 1) {
					console.log('InfoPage --> getUserInfo success');
					this.cardUserInfo = result.data.cardUserInfo;
					this.userChoice = result.data.cardUserInfo.userChoice;
					return resolve(true);
				} else if (result.error === 1) {
					if(result.errorCode === 201 || result.errorCode === 104){
						this.ideateProvider.toast('', 'User not found.');
						this.ideateProvider.navigate('tabs/home');
					}else if(result.errorCode === 202){
						this.ideateProvider.toast('', this.ideateProvider.config.defaultErrorMsg); // blocked by user
						this.ideateProvider.infoModal.dismiss();
					}else {
						this.ideateProvider.toast('', this.ideateProvider.config.defaultErrorMsg);
					}
				} else {
					this.ideateProvider.toast('', 'API_ERROR : ' + this.ideateProvider.config.defaultErrorMsg);
				}
			}).catch(async (httpError) => {
				await this.utilsService.hide();
				this.ideateProvider.toast('', 'CONNECTIVITY_ERROR : ' + httpError.errorMessage);
			});
		});
	}

	goBack() {
		this.ideateProvider.navigateBack();
	}

	async userActionMenu(){
		const actionSheetButtons = [];
		actionSheetButtons.push({
			text: 'Report',
			role: 'destructive',
			icon: 'alert-circle-outline',
			data: {
				type: 'report',
			},
			handler: async () => {
				this.ideateProvider.reportUserModal = await this.modalController.create({
					component: ReportUserComponent,
					componentProps: {
						cardUserId: this.cardUserId,
						userName: this.cardUserInfo.name,
					},
				});
				await this.ideateProvider.reportUserModal.present().then(async ()=>{
					this.ideateProvider.reportModalIsOpen = true;
					await this.ideateProvider.closeModalOnSwipe(this.ideateProvider.reportUserModal);
				});
				await this.ideateProvider.reportUserModal.onDidDismiss().then(() => {
					this.ideateProvider.reportModalIsOpen = false;
				});
			}
		});

		const blockStatus = this.cardUserInfo.current_user_block_status;
		actionSheetButtons.push({
			text: (blockStatus === '') ? 'Block' : 'Unblock',
			role: 'destructive',
			icon: 'ban-outline',
			data: {
				type: (blockStatus === '') ? 'block' : 'unblock',
			},
			handler: async () => {

				if(blockStatus === ''){
					swal.fire({
						title: 'Are you sure to block this user?',
						showCancelButton: true,
						confirmButtonText: 'Block',
						backdrop: false,
					}).then(async (result) => {
						if (result.isConfirmed) {
							await this.blockUser();
						}
					});
				}else{
					await this.blockUser();
				}
			}
		});

		if(actionSheetButtons.length !== 0){
			const actionSheet = await this.actionSheetController.create({
				header: 'Action',
				buttons: actionSheetButtons,
			});
			await actionSheet.present();

			const { role, data } = await actionSheet.onDidDismiss();
			console.log('onDidDismiss resolved with role and data', role, data);
		}
	}

	async blockUser(){
		await this.account.blockUser(this.cardUserId).then(async (result: any) => {
			if (result.success === 1) {

				if(result.data.status === 'block'){
					this.modalController.dismiss({
						blockedUser: this.cardUserInfo
					},'blockedUser');
				}else{
					await this.getUserInfo(this.cardUserId);
					this.cardUserInfo.block_status = '';
					this.account.blockedUsers = this.account.blockedUsers.filter(user => user.user_id !== this.cardUserId);
				}

				// block the message section to send the message
				if(this.messagesService.roomId !== 0 && this.ideateProvider.currentUrl === '/tabs/chat'){
					this.messagesService.getUserMessages(1, 'new', true, false);
				}

				const message = (result.data.status === 'block') ? 'User Blocked' : 'User Unblocked';
				swal.fire({
					icon: 'success',
					text: message,
					backdrop: false
				});

			} else if (result.error === 1) {
				if(result.errorCode === 201 || result.errorCode === 104){
					this.ideateProvider.toast('', 'User not found.');
					this.ideateProvider.navigate('tabs/home');
				} else {
					this.ideateProvider.toast('', this.ideateProvider.config.defaultErrorMsg);
				}
			} else {
				this.ideateProvider.toast('', 'API_ERROR : ' + this.ideateProvider.config.defaultErrorMsg);
			}
		});
	}

	async imageView(event) {
		if(event.target.localName === 'div'){
			if(this.cardUserInfo.public_picture !== ''){
				this.modalImgSrc = this.cardUserInfo.public_picture;
			}else{
				return false;
			}
			await this.imgModal.present();
			this.imgModal.onDidDismiss().then(()=>{
			});
		}
	}

}
