import { NgModule, Component } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
	{
		path: 'login',
		loadChildren: () =>
			import('./pages/login/login.module').then((m) => m.LoginPageModule),
	},
	{
		path: 'signUp',
		loadChildren: () =>
			import('./pages/signUp/signUp.module').then((m) => m.SignUpPageModule),
	},
	{
		path: 'verify',
		loadChildren: () =>
			import('./pages/verify/verify.module').then((m) => m.VerifyPageModule),
	},
	{
		path: 'tabs',
		loadChildren: () =>
			import('./pages/shared/tabs/tabs.module').then(m => m.TabsPageModule)
	},
	{
		path: 'terms-and-condition',
		loadChildren: () => import('./pages/terms-and-condition/terms-and-condition.module').then(m => m.TermsAndConditionPageModule)
	},
	{
		path: 'login-as',
		loadChildren: () => import('./pages/login-as/login-as.module').then( m => m.LoginAsPageModule)
	},
];

@NgModule({
	imports: [
		RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
	],
	exports: [RouterModule],
})
export class AppRoutingModule { }
