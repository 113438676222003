/* eslint-disable eqeqeq */
import { Injectable } from '@angular/core';
import {
	LoadingController,
	AlertController,
	ToastController,
	NavController,
} from '@ionic/angular';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';

@Injectable({
	providedIn: 'root',
})
export class UtilsService {
	loader: any;
	isLoading = false;

	public countryCode: string;
	public likeRejectPageActive: string;

	// for user info page
	public cardUserInfo: any;

	// eslint-disable-next-line @typescript-eslint/naming-convention
	public LoaderSetTimeout: any = '';

	constructor(
		public loadingCtrl: LoadingController,
		public alertCtrl: AlertController,
		private toastCtrl: ToastController,
		public router: Router,
	) {}
	/*
	Start Loader
	Call this method to Start your Loader
	*/

	async show() {
		this.isLoading = true;
		if(this.LoaderSetTimeout != ''){
			clearTimeout(this.LoaderSetTimeout);
		}

		try{
			return await this.loadingCtrl
			.create({
				// duration: 5000,
				cssClass: 'custom-loader',
				spinner: null,
			})
			.then((a) => {
				a.present().then(() => {
					this.isLoading = true;
					// remove the loader if it stuck more than 15 second
					this.LoaderSetTimeout = setTimeout(async () => {
						console.log('loader after 10 seconds');
						a.dismiss();
						this.isLoading = false;
						/* const loader = document.getElementById('ion-overlay-1');
						if(loader){
							this.hide();
							const toast = await this.toastCtrl.create({
								message: 'Please try again.',
								duration: 60000,
								color: 'dark',
								position: 'bottom',
								buttons: [
									{
										text: 'Ok',
										role: 'cancel'
									}
								]
							});
							toast.present();
						} */
					}, 10000);

					console.log('loading....');
					if (!this.isLoading) {
						a.dismiss().then(() => console.log('abort presenting'));
					}
				}).catch((e) => {
					console.log(e);
				});
			});

		}catch(error){
			console.log('UtilsService show loader error',error);
		}
	}
	successMsg(title) {
		Swal.fire({
			icon: 'success',
			title,
			showConfirmButton: false,
			timer: 1500,
		});
	}
	async hide() {
		if (this.isLoading) {
			this.isLoading = false;
			if(this.LoaderSetTimeout != ''){
				clearTimeout(this.LoaderSetTimeout);
			}
			return await this.loadingCtrl.dismiss().then(() => {
				console.log('loaded');
				if(this.LoaderSetTimeout != ''){
					clearTimeout(this.LoaderSetTimeout);
				}
			}).catch((e) => {
				console.log(e);
			});
		}
	}

	/*
		Show Warning Alert Message
		param : msg = message to display
		Call this method to show Warning Alert,
		*/
	async showWarningAlert(msg) {
		const alert = await this.alertCtrl.create({
			header: 'Warning',
			message: msg,
			buttons: ['ok'],
		});

		await alert.present();
	}

	async showSimpleAlert(msg) {
		const alert = await this.alertCtrl.create({
			header: 'warning',
			message: msg,
			buttons: ['ok'],
		});

		await alert.present();
	}

	/*
	Show Error Alert Message
	param : msg = message to display
	Call this method to show Error Alert,
	*/
	async showErrorAlert(msg) {
		const alert = await this.alertCtrl.create({
			header: 'Error',
			message: msg,
			buttons: ['ok'],
		});

		await alert.present();
	}

	/*
		param : email = email to verify
		Call this method to get verify email
		*/
	async getEmailFilter(email) {
		const emailfilter = /^[\w._-]+[+]?[\w._-]+@[\w.-]+\.[a-zA-Z]{2,6}$/;
		if (!emailfilter.test(email)) {
			const alert = await this.alertCtrl.create({
				header: 'warning',
				message: 'Please enter valid email',
				buttons: ['ok'],
			});
			await alert.present();
			return false;
		} else {
			return true;
		}
	}

	/*
		Show Toast Message on Screen
		param : msg = message to display, color= background
			color of toast example dark,danger,light. position  = position of message example top,bottom
		Call this method to show toast message
		*/

	async showToast(msg, color, positon) {
		const toast = await this.toastCtrl.create({
			message: msg,
			duration: 2000,
			color,
			position: positon,
		});
		toast.present();
	}

	async errorToast(msg, color?) {
		const toast = await this.toastCtrl.create({
			message: msg,
			duration: 2000,
			color: color ? 'dark' : 'light',
		});
		toast.present();
	}

	apiErrorHandler(err) {
		console.log('error', err);
	}
}
