/* eslint-disable max-len */
import { Injectable } from '@angular/core';
// import { Plugins } from '@capacitor/core';
import { Http, URLSearchParams, Headers, RequestOptions, Response, HttpModule } from '@angular/http';
import { FormControl, AbstractControl, ValidationErrors } from '@angular/forms';
import { Storage } from '@ionic/storage-angular';
import { timeout, finalize } from 'rxjs/operators';
import { NavigationEnd, Router } from '@angular/router';
import { Location } from '@angular/common';
import { environment } from '../../environments/environment';

import { Platform, AlertController, ToastController, LoadingController, getPlatforms, isPlatform, NavController, ModalController } from '@ionic/angular';

import { AndroidPermissions } from '@awesome-cordova-plugins/android-permissions/ngx';
import { Camera, CameraResultType, CameraSource, Photo } from '@capacitor/camera';
import { Filesystem, Directory } from '@capacitor/filesystem';

import { FileTransfer, FileUploadOptions, FileTransferObject } from '@awesome-cordova-plugins/file-transfer/ngx';
import { File } from '@awesome-cordova-plugins/file/ngx';
import { Subject } from 'rxjs';
import swal from 'sweetalert2';
import { InfoPage } from '../pages/info/info.page';
// const { app } = Plugins;

import { GestureController, Gesture } from '@ionic/angular';

// import { PushNotifications, Token } from '@capacitor/push-notifications';

@Injectable()
export class IdeateProvider {

	public config = environment;

	public isDarkMode = false;
	// eslint-disable-next-line @typescript-eslint/naming-convention
	public SITE_NAME = this.config.SITE_NAME;

	public fcmToken = '';
	public currentUrlSubject = new Subject();
	public currentUrl = '';
	public currentUrl$ = this.currentUrlSubject.asObservable();

	public deviceCountry = '';
	public maintenanceMode = false; // this variable can make whole app down
	public updateAvailable: any;

	public infoModalIsOpen = false; // this variable used for user information popup open or not
	public infoModal: any;
	public reportModalIsOpen = false; // this variable user for report user page open or not from user pop up
	public reportUserModal: any;

	public lat: any = '';
	public lng: any = '';

	private loader: any;
	// private lastBackPressTime = 0;

	constructor(
		public router: Router,
		private navControl: NavController,
		public location: Location,
		public platform: Platform,
		public http: Http,
		public storage: Storage,
		public alertCtrl: AlertController,
		private toastCtrl: ToastController,
		private loadingCtrl: LoadingController,
		private transfer: FileTransfer,
		private file: File,
		private androidPermissions: AndroidPermissions,
		private modalController: ModalController,
		private gestureController: GestureController,
	) {
		this.platform.ready().then(async () => {
			/* this.config.deviceType = (this.platform.is('ios')) ? 'ios' : this.config.deviceType;
			this.config.deviceType = (this.platform.is('android')) ? 'android' : this.config.deviceType; */
			this.config.deviceType = this.config.deviceType;

			if(isPlatform('mobileweb')){
				console.log('Running on Browser devices.....');
				this.config.deviceType = 'browser';
			} else if(isPlatform('android')){
				console.log('Running on android devices.....');
				this.config.deviceType = 'android';
			} else if(isPlatform('ios')){
				console.log('Running on ios devices.....');
				this.config.deviceType = 'ios';
			}

			console.log('IdeateProvide --> constructor');
			await this.storage.create();

			const deviceWidth = window.innerWidth;

			this.platform.resize.subscribe(() => {
				/* if(this.currentUrl == '/tabs/chat' && window.innerWidth < 950){
					this.navigate('tabs/chat-list');
				} else if(this.currentUrl == '/tabs/chat-list' && window.innerWidth > 950){
					this.navigate('tabs/chat');
				} */

				// reloading the page when device width major change
				if(this.currentUrl === '/tabs/chat'){
					if(deviceWidth > 950){
						if(window.innerWidth < 950){
							window.location.reload();
						}
					}else{
						if(window.innerWidth > 950){
							window.location.reload();
						}
					}
				}
			});

			await this.deviceRestriction();

			// back button handle for app common
			this.platform.backButton.subscribeWithPriority(99999,async () => {
				console.log('Ideate Provider Back Button clicked...', this.currentUrl);

				// double press to exit the app
				/* if(this.currentUrl === '/tabs/home'){
					console.log(this.reportModalIsOpen, this.infoModalIsOpen);
					if(!this.reportModalIsOpen && !this.infoModalIsOpen){
						// Calculate the current time
						const currentTime = new Date().getTime();
						if (currentTime - this.lastBackPressTime < 2000) {
							app.exitApp();
						} else {
							this.lastBackPressTime = currentTime;
							this.toast('','Press again to exit.');
						}
					}
				} */

				if(!this.reportModalIsOpen && !this.infoModalIsOpen){
					if(['/tabs/suggestion', '/tabs/grid-profiles/likes', '/tabs/grid-profiles/rejected'].includes(this.currentUrl)){
						this.navigate('tabs/home');
						return false;
					}
				}

				await this.closeModalAndStop();
				return false;
			});

		});

		// current route event subscribe
		// this code for removing the user from the userChoice, if it come from the user info page.
		this.router.events.subscribe((event) => {
			if (event instanceof NavigationEnd) {
				console.warn('App Navigation to --> ',event.url);
				this.currentUrl = event.url.toString();
				this.currentUrlSubject.next();
			}
		});
	}

	// navigate to user info page passing user id
	async openInfo(cardUserId: number) {

		return new Promise(async resolve => {
			this.infoModal = await this.modalController.create({
				component: InfoPage,
				componentProps: {
					cardUserId
				},
				cssClass: 'full_view_modal'
			});
			await this.infoModal.present().then(async ()=>{
				this.infoModalIsOpen = true;
				await this.closeModalOnSwipe(this.infoModal);
			});
			await this.infoModal.onDidDismiss().then((data) => {
				this.infoModalIsOpen = false;
				if(data.role === 'blockedUser'){
					return resolve(data.data.blockedUser);
				}else{
					resolve(undefined);
				}
			});
		});
	}

	async closeModalOnSwipe(modal){
		const gesture: Gesture = this.gestureController.create({
			el: modal,
			gestureName: 'swipe-to-close',
			direction: 'x',
			threshold: 0,
			canStart: ev => true,
			onStart: ev => { },
			onMove: ev => { },
			onEnd: ev => {
				if (ev.deltaX > 100 && ev.velocityX > 0.5) {
					modal.dismiss();
				}
			}
		});
		gesture.enable();
	}

	async closeModalAndStop(){
		if(this.infoModalIsOpen){

			if(this.reportModalIsOpen){
				this.reportUserModal.dismiss();
				this.reportModalIsOpen = false;
				document.addEventListener('backbutton', (event) => {
					event.preventDefault();
					event.stopPropagation();
					console.log('closeModalAndStop force stop navigation.');
				}, false);
				return false;
			}

			this.infoModal.dismiss();
			this.infoModalIsOpen = false;
			document.addEventListener('backbutton', (event) => {
				event.preventDefault();
				event.stopPropagation();
				console.log('closeModalAndStop force stop navigation.');
			}, false);
			return false;
		}
	}

	async removeStorage(){
		await this.storage.clear();
	}

	async alert(message: string, header: string = '', subHeader: string = '') {
		const alertOptions: any = {};
		alertOptions.header = header;
		alertOptions.subHeader = subHeader;
		alertOptions.message = message;
		alertOptions.buttons = [{
			text: 'OK',
			role: 'cancel'
		}];
		const alert = await this.alertCtrl.create(alertOptions);
		alert.present();
	}

	async presentConfirm(message: string, header: string = '', subHeader: string = ''): Promise<boolean> {
		let resolveFunction: (confirm: boolean) => void;
		const promise = new Promise<boolean>(resolve => {
			resolveFunction = resolve;
		});
		const alertConfirmOptions: any = {};
		alertConfirmOptions.header = header;
		alertConfirmOptions.subHeader = subHeader;
		alertConfirmOptions.message = message;
		alertConfirmOptions.buttons = [
			{
				text: 'Ok',
				handler: () => resolveFunction(true)
			},
			{
				text: 'Cancel',
				role: 'cancel',
				handler: () => resolveFunction(false)
			}
		];
		const alert = await this.alertCtrl.create(alertConfirmOptions);
		await alert.present();
		return promise;
	}

	async toast(title: string = '', message: string, duration: any = 3000, button: any = 'OK', position: string = 'bottom') {
		const toastOptions: any = {};
		if (message === undefined) {
			message = 'Something went wrong. Try again later.';
		}
		title += (title !== '') ? '\n' : '';
		toastOptions.position = position;
		toastOptions.message = title + message;
		if (button !== false) {
			toastOptions.buttons = [{
				text: button,
				role: 'cancel',
			}];
		}
		if (duration !== false) {
			toastOptions.duration = duration;
		}
		const toast = await this.toastCtrl.create(toastOptions);
		toast.present();
	}

	async showLoader(message = 'Please wait...') {
		if (!this.loader) {
			const loaderOptions: any = {
				animated: true,
				spinner: 'bubbles'
			};
			loaderOptions.message = message;
			this.loader = await this.loadingCtrl.create(loaderOptions);
			await this.loader.present().then(() => { }).catch(() => {
			});
		}

		return Promise.resolve();
	}

	async hideLoader() {
		if (this.loader) {
			await this.loader.dismiss().then(() => {
				this.loader = null;
			}).catch(() => {
				this.loader = null;
			});
		}
		return Promise.resolve();
	}

	isEmptyObj(obj: any = {}) {
		return (obj === null || typeof obj !== 'object' || Object.keys(obj).length === 0);
	}

	isValidJSON(strJSON: string) {
		try {
			JSON.parse(strJSON);
		} catch (e) {
			return false;
		}
		return true;
	}

	objectToFormData(obj: Record<string, any>, form?: FormData, namespace?: string): FormData {
		const formData = form || new FormData();
		for (const property in obj) {
			if (!obj.hasOwnProperty(property)) { // (|| !obj[property])
				continue;
			}
			const formKey = namespace ? `${namespace}[${property}]` : property;
			if (obj[property] instanceof Date) {
				formData.append(formKey, obj[property].toISOString());
			} else if (typeof obj[property] === 'object' && !(obj[property] instanceof File)) {
				this.objectToFormData(obj[property], formData, formKey);
			} else {
				formData.append(formKey, obj[property]);
			}
		}
		return formData;
	}

	// eslint-disable-next-line max-len
	async makeHttpRequest(apiEndPoint: string, params: any = {}, method = 'post', timeoutInterval: number = (1000 * 60 * 60), headers: any = {}): Promise<any> {
		if (!headers || !headers.Authorization || headers.Authorization === '') {
			headers.Authorization = this.config.APIAccessToken;
		}

		const data = new URLSearchParams();
		for (const name in params) {
			if (params.hasOwnProperty(name)) {
				data.append(name, params[name]);
			}
		}

		const dataQS = data.toString();
		if (method === 'get') {
			try {
				const response = await this.http.get(this.config.APIurl + apiEndPoint + '?' + dataQS).toPromise();
				const responseJson = response.json();
				return await Promise.resolve(responseJson || '');
			} catch (error) {
				return await Promise.reject(this.getHttpError(error));
			}
		}
		else if (method === 'post') {
			// headers['Content-Type'] = 'multipart/form-data';
			const httpHeaders = new Headers(headers);
			const httpOptions = new RequestOptions({ headers: httpHeaders });

			const reqParams = this.objectToFormData(params);
			try {
				try {
					// eslint-disable-next-line max-len
					const response = await this.http.post(this.config.APIurl + apiEndPoint, reqParams, httpOptions).pipe(timeout(timeoutInterval)).toPromise();
					const responseJson = response.json();
					return await Promise.resolve(responseJson || '');
				} catch (handler) {
					return await Promise.reject(this.getHttpError(handler));
				}
			} catch (error) {
				return await Promise.reject(this.getHttpError(error));
			}
		}
		else {
			return Promise.reject(this.getHttpError({}));
		}
	}

	pad(num: number, size: number = 1) {
		let str: string = num + '';
		while (str.length < size) {
			str = '0' + str;
		}
		return str;
	}

	getArrayIndex(array, key, value) {
		for (let i = 0; i < array.length; i++) {
			if (array[i][key] === value) {
				return i;
			}
		}
		return false;
	}

	getHttpError(error: Response | any) {
		let errMsg = 'Invalid Request!';
		if (error instanceof Response) {

			if (this.getObjVal(error, ['status']) === 0) {
				errMsg = 'Failed to connect to server.';
			}
			else {
				errMsg = `HTTP_ERROR ${this.getObjVal(error, ['status'], '')} : ${this.getObjVal(error, ['statusText'], errMsg)}`;
			}
		} else {
			if (this.getObjVal(error, ['status']) === 0) {
				errMsg = 'Failed to connect to server.';
			}
			else {
				errMsg = `HTTP_ERROR ${this.getObjVal(error, ['status'], '')} : ${this.getObjVal(error, ['statusText'], errMsg)}`;
			}
		}
		error.errorMessage = errMsg;
		return error;
	}

	getObjVal(obj, properties = [], defaultVal: any = '') {
		if (this.isEmptyObj(obj)) {
			return defaultVal;
		}
		else {
			if (properties.length >= 1) {
				const property = properties.shift();
				if (typeof obj[property] === 'undefined' || obj[property] == null) {
					return defaultVal;
				}
				else {
					if (properties.length > 0) {
						return this.getObjVal(obj[property], properties, defaultVal);
					}
					else {
						return obj[property];
					}
				}
			}
			else {
				return defaultVal;
			}
		}
	}

	getValidationErrors(form: any, validationMessages: any, isFormSubmitted = false): any {
		if (form && Object.keys(validationMessages).length > 0) {
			for (const field in validationMessages) {
				if (validationMessages.hasOwnProperty(field)) {
					validationMessages[field].error = '';
					const control = form.get(field);
					if (control && control.enabled && (control.dirty || control.touched || isFormSubmitted) && control.invalid) {
						const messages = validationMessages[field];
						for (const key in control.errors) {
							if (control.errors.hasOwnProperty(key)) {
								validationMessages[field].error += (validationMessages[field].error !== '') ? '<br/>' : '';
								validationMessages[field].error += messages[key];
								break;
							}
						}
					}
				}
			}
		}
		return validationMessages;
	}

	getValidationErrorForField(form: any, validationMessages: any, validateField: any): any {
		if (form && Object.keys(validationMessages).length > 0) {
			for (const field in validationMessages) {
				if (validationMessages.hasOwnProperty(field)) {
					if(field === validateField){
						const control = form.get(field);
						if (control && control.enabled && (control.dirty || control.touched) && control.invalid ) {
							const messages = validationMessages[field];
							validationMessages[field].error = '';
							// eslint-disable-next-line guard-for-in
							for (const key in control.errors) {
								if (control.errors.hasOwnProperty(key)) {
									console.log('error', key);
									console.log('messages', messages);
									validationMessages[field].error += messages[key];
									break;
								}
							}
							return validationMessages[field].error;
						}
					}
				}
			}
		}
		return '';
	}

	navigate(page: any, params: any = {}) {
		// this.navControl.navigateRoot([page, params]);
		this.router.navigate([page, params]);
	}

	navigateWithNavControl(page: any, params: any = {}){
		this.navControl.navigateRoot([page, params]);
	}

	pageReload() {
		window.location.reload();
	}

	navigateBack() {
		this.location.back();
	}

	ucFirst(str: string = '') {
		str = (str.length > 0) ? str.charAt(0).toUpperCase() + str : str;
		str = (str.length > 1) ? str.charAt(0) + str.slice(2) : str;
		return str;
	}

	normalizeString(str: string = '') {
		return str.replace(/[-_]/g, ' ');
	}

	initConfig() {
		this.storage.get('config').then((config) => {
			if (!this.isEmptyObj(config)) {
				this.config = config;
			}
			else {
				this.config = environment;
			}
		});
	}

	updateConfig(config: any) {
		return new Promise((resolve, reject) => {
			this.hideLoader();
			config = Object.assign(this.config, config);
			this.storage.set('config', config).then(() => {
				this.initConfig();
				resolve(config);
			}).catch((ex) => {
				reject(ex);
			});
		});
	}

	clearConfig() {
		return new Promise((resolve, reject) => {
			this.storage.remove('config').then(() => {
				this.initConfig();
				resolve(true);
			}).catch((ex) => {
				reject(ex);
			});
		});
	}

	timeAgo(ts) {
		const currTS = Math.round(Date.parse(new Date().toUTCString()) / 1000);
		let difference = Math.floor(currTS - ts);
		const periods = ['second', 'minute', 'hour', 'day', 'week', 'month', 'year', 'decade'];
		const lengths = [60, 60, 24, 7, 4.35, 12, 10];
		let j;
		for (j = 0; difference >= lengths[j]; j++) {
			difference /= lengths[j];
		}
		difference = Math.floor(difference);
		if (difference !== 1) {
			periods[j] += 's';
		}
		if (difference <= 10 && periods[j].indexOf('second') === 0) {
			return 'Just now';
		} else {
			return difference + ' ' + periods[j] + ' ago';
		}
	}
	timestamp() {
		return Math.round(Date.parse(new Date().toUTCString()) / 1000);
	}
	tSecFromObj(dateObject: Date) {
		return (dateObject.getHours() * 3600) + (dateObject.getMinutes() * 60) + (dateObject.getSeconds());
	}
	tDuration(totalSecs: string | number = '0') {
		totalSecs = totalSecs.toString();
		const secNum = parseInt(totalSecs, 10); // don't forget the second param
		const hours = Math.floor(secNum / 3600);
		const minutes = Math.floor((secNum - (hours * 3600)) / 60);
		const seconds = secNum - (hours * 3600) - (minutes * 60);
		return this.pad(hours, 2) + ':' + this.pad(minutes, 2) + ':' + this.pad(seconds, 2);
	}
	tFormatFromStr(time: string | any[]) {
		time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];
		if (time.length > 1) {
			time = time.slice(1, -1);
			time[3] = +time[0] < 12 ? ' AM' : ' PM';
			time[0] = +time[0] % 12 || 12;
		}
		time[0] = this.pad(+time[0], 2);
		time[2] = this.pad(+time[2], 2);
		return time.join('');
	}
	dFormatFromStr(strDate = '0000-00-00', dateSeparator = '-', defaultVal = 'N/A') {
		if (strDate !== '0000-00-00') {
			const objDate = new Date(strDate);
			// eslint-disable-next-line max-len
			const formattedDate = this.pad((objDate.getUTCMonth() + 1), 2) + dateSeparator + this.pad(objDate.getUTCDate(), 2) + dateSeparator + objDate.getUTCFullYear();
			return formattedDate;
		}
		return defaultVal;
	}
	dtFormatFromStr(strDateTime = '0000-00-00 00:00:00', dateSeparator = '-', defaultVal = 'N/A') {
		let formattedDateTime = defaultVal;
		if (strDateTime !== '0000-00-00 00:00:00') {
			const arrDateTime = strDateTime.split(' ');
			if (arrDateTime.length === 2) {
				formattedDateTime = this.dFormatFromStr(arrDateTime[0], dateSeparator) + ' ' + this.tFormatFromStr(arrDateTime[1]);
			}
		}
		return formattedDateTime;
	}
	dtFormatFromObj(objDate: Date) {
		return this.dtFormatFromStr(this.dtFormatToDB(objDate));
	}
	dtFormatFromStrToObj(strDateTime: string) {
		return new Date(Date.parse(strDateTime.replace(/[-]/g, '/')));
	}
	dFormatToDB(objDate: Date | string) {
		let strDate = '';
		if (objDate instanceof Date || (typeof objDate === 'string' && objDate.split(' ').join('') !== '')) {
			objDate = (objDate instanceof Date) ? objDate : new Date(objDate);
			if (objDate instanceof Date) {
				strDate += objDate.getFullYear() + '-' + this.pad((objDate.getMonth() + 1), 2) + '-' + this.pad(objDate.getDate(), 2);
			}
		}
		return strDate.trim();
	}
	tFormatToDBFrom24HoursFormat(time: string) {
		let hours = Number(time.match(/^(\d+)/)[1]);
		const minutes = Number(time.match(/:(\d+)/)[1]);
		const AMPM = time.match(/\s(.*)$/)[1];
		if (AMPM === 'PM' && hours < 12) {
			hours = hours + 12;
		}
		if (AMPM === 'AM' && hours === 12) {
			hours = hours - 12;
		}
		let sHours = hours.toString();
		let sMinutes = minutes.toString();
		if (hours < 10) {
			sHours = '0' + sHours;
		}
		if (minutes < 10) {
			sMinutes = '0' + sMinutes;
		}
		const sTime = sHours + ':' + sMinutes;
		return sTime;
	}
	tFormatToDB(objTime: Date | string) {
		let strTime = '';
		if (objTime instanceof Date || (typeof objTime === 'string' && objTime.split(' ').join('') !== '')) {
			objTime = (objTime instanceof Date) ? objTime : new Date(objTime);
			if (objTime instanceof Date) {
				// eslint-disable-next-line max-len
				strTime += this.pad(objTime.getHours(), 2) + ':' + this.pad(objTime.getMinutes(), 2) + ':' + this.pad(objTime.getSeconds(), 2);
			}
		}
		return strTime.trim();
	}
	dtFormatToDB(objDate: Date | string, objTime: Date | boolean = false) {
		let strDateTime = '';
		if (objDate instanceof Date || (typeof objDate === 'string' && objDate.split(' ').join('') !== '')) {
			objDate = (objDate instanceof Date) ? objDate : new Date(objDate);
			strDateTime = this.dFormatToDB(objDate) + ' ';
			if (objTime instanceof Date) {
				strDateTime += this.tFormatToDB(objTime);
			} else {
				strDateTime += this.tFormatToDB(objDate);
			}
		}
		return strDateTime.trim();
	}

	showMap(latitude, longitude) {

		const destination = latitude + ',' + longitude;
		if (this.config.deviceType === 'ios') {
			window.open('maps://?q=' + destination, '_system');
		} else {
			// android
			const label = encodeURI('Service Location');
			window.open('geo:0,0?q=' + destination + '(' + label + ')', '_system');
		}
	}

	/* new */
	convertBlobToBase64 = (blob: Blob) => new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.onerror = reject;
		reader.onload = () => {
			resolve(reader.result);
		};
		reader.readAsDataURL(blob);
	});

	async readAsBase64(photo: Photo) {
		if (this.platform.is('hybrid')) {
			const file = await Filesystem.readFile({
				path: photo.path
			});
			return file.data;
		} else {
			// Fetch the photo, read as a blob, then convert to base64 format
			const response = await fetch(photo.webPath);
			const blob = await response.blob();
			return await this.convertBlobToBase64(blob) as string;
		}
	}

	async toBase64(file) {
		return new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.readAsDataURL(file);

			// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
			function getFileReader(): FileReader {
				const fileReader = new FileReader();
				// eslint-disable-next-line @typescript-eslint/dot-notation
				const zoneOriginalInstance = (fileReader as any)['__zone_symbol__originalInstance'];
				return zoneOriginalInstance || fileReader;
			}

			const newInstance = getFileReader();
			newInstance.readAsDataURL(file);
			newInstance.onload = (imgsrc) => {
				const base64File = (imgsrc.target as FileReader).result;
				return resolve(base64File);
			};
		});
	}

	async askAndroidPermission(list: any, message = '' ) {
		return new Promise(async resolve => {

			list.map((v) => {
				this.androidPermissions.checkPermission(v).then(
					result => console.log(v,'Has permission?',result.hasPermission),
					async () => {
						await this.androidPermissions.requestPermission(v);
					}
				);
			});

			if(list.length > 1){
				console.log('requested multiple permissions', list);
				await this.androidPermissions.requestPermissions(list).then((permission) => {
					if(permission.hasPermission){
						return resolve(true);
					} else {
						if(message !== ''){
							this.toast('', message);
						}
						return resolve(false);
					}
				}, () => {
					if(message !== ''){
						this.toast('', message);
					}
					return resolve(false);
				});
			}else{
				console.log('requested single permission', list);
				list = list[0];
				await this.androidPermissions.requestPermission(list).then((permission) => {
					if(permission.hasPermission){
						return resolve(true);
					} else {
						if(message !== ''){
							this.toast('', message);
						}
						return resolve(false);
					}
				}, () => {
					if(message !== ''){
						this.toast('', message);
					}
					return resolve(false);
				});
			}
		});
	}

	async fileTransferToWeb(fileOptions: any, apiUrl: string) {
		return new Promise<void>(async (resolve, reject) => {

			const fullPath = fileOptions.fullPath;
			const filename = fileOptions.name;

			// eslint-disable-next-line prefer-const
			let fileTransfer: FileTransferObject = this.transfer.create();
			// eslint-disable-next-line prefer-const
			let fileOption: FileUploadOptions = {
				fileKey: fileOptions.key,
				fileName: filename,
				httpMethod: 'POST',
				params: {
					// eslint-disable-next-line @typescript-eslint/naming-convention
					user_id: fileOptions.user_id,
					// eslint-disable-next-line @typescript-eslint/naming-convention
					auth_token: fileOptions.auth_token,
					action: 'add',
					pictureType: fileOptions.pictureType,
				},
				headers: {
					authorization: this.config.APIAccessToken
				}
			};
			console.log(fileOptions);

			fileTransfer.upload(fullPath, this.config.APIurl + apiUrl, fileOption)
				.then(async (result) => {
					const uploadResult = JSON.parse(result.response);
					console.log(uploadResult);
					if (uploadResult.success === 1) {
					} else {
						if (uploadResult.errorCode === 101 || uploadResult.errorCode === 102) {
							this.toast(this.config.defaultAuthErrorTitle, this.config.defaultAuthErrorMessage);
							// await this.account.doLogout();
							resolve(uploadResult);
						} else if (uploadResult.errorCode === 104) {
							await this.toast('Uploading', uploadResult.errMsg);
						} else {
							this.toast(this.config.defaultErrorTitle, 'API Error: ' + this.config.defaultErrorMsg);
						}
					}
					resolve(uploadResult);
				}, async (result) => {
					const uploadResult = result.response;
					await this.toast('Uploading', 'Fail to uploading.');
					console.log(uploadResult);
					resolve(uploadResult);
				});
		});
	}

	showBrowserNotification(title, body){
		const SITE_NAME = this.SITE_NAME;
		console.log('ideateProvider -> showBrowserNotification');
		console.log(title, body);
		if(this.config.deviceType === 'browser'){
			// show android ios notification here
			Notification.requestPermission((permission) => {
				const notificationTitle = SITE_NAME+ ' - '+title;
				const options = {
					body,
					icon: 'assets/icon/favicon.png'
				};
				if (permission === 'granted') {
					const n = new Notification(notificationTitle, options);
				}
				if (permission === 'denied') {
					console.warn('Please allow notification.');
				}
			});
		}
	}


	playNotificationSound(){
		console.warn('Playing notifications sound.');
		/* const src = 'assets/audio/msg.mp3';
		const audio = new Audio(src);
		audio.play();
		window.addEventListener('unhandledrejection', (e) => {
			if(e.reason.message.indexOf('play()') !== -1){
				audio.addEventListener('canplaythrough', () => {
					audio.play().catch(() => {
						window.addEventListener('click', () => {
							audio.play();
						}, { once: true });
					});
				});
			}
		}); */
	}

	zeroPad(num, places) {
		const zero = places - num.toString().length + 1;
		return Array(+(zero > 0 && zero)).join('0') + num;
	}

	requestForNotificationPermission(){
		return new Promise(async resolve => {
			console.log('Requesting permission...');

			if ('Notification' in window) {
				Notification.requestPermission()
					.then((permission) => {
						console.log('Permission: ', permission);
						if (permission === 'granted') {
							// The user granted permission; you can now show notifications.
							// this.showBrowserNotification('Permission Granted!', 'You will now receive notifications.');
							return resolve(true);
						} else if (permission === 'denied') {
							// The user denied permission.
							console.log('Notification permission denied.');
							this.showBrowserNotification('Permission Denied!', 'You have denied notification permission.');
							return resolve(false);
						} else {
							// The user dismissed the permission prompt.
							console.log('Notification permission prompt dismissed.');
							this.showBrowserNotification('Permission Dismissed!', 'You have dismissed the notification permission prompt.');
							return resolve(false);
						}
					})
					.catch((err) => {
						console.error('Error requesting permission: ', err);
						return resolve(false);
					});
			} else {
				console.warn('Notifications are not supported in this browser.');
				return resolve(false);
			}
		});
	}

	isValidEmail(c){
		if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(c)){
			return (true);
		}else{
			return (false);
		}
	}

	deviceRestriction(){
		return new Promise((resolve, reject) => {
			const requestParams: any = {};
			requestParams.device_platform = this.config.deviceType;
			requestParams.app_version = this.config.AppVersion;
			requestParams.device_fcm_token = this.fcmToken;
			this.makeHttpRequest('common/checkingDevice', requestParams).then(async (result) => {
				this.deviceCountry = result.data.country_code;
				this.storage.set('countryCode',this.deviceCountry);
				if (result.success === 1) {
				} else if (result.error === 1) {
					if (result.errorCode === 301) {
						console.log('maintenance mode is on');
						this.maintenanceMode = true;
					} else if (result.errorCode === 302) {
						/* this.storage.set('countryCode',this.deviceCountry);
						this.updateAvailable = {
							link: result.data.link,
							urgencyLevel: result.data.urgencyLevel
						}; */
						console.log('app update available', this.updateAvailable);
					}
				} else {
					this.toast('', 'API_ERROR : ' + this.config.defaultErrorMsg);
				}
				return resolve(true);
			}).catch(async (httpError) => {
				this.toast('', 'CONNECTIVITY_ERROR : ' + httpError.errorMessage);
			});
		});
	}

	randomNumber(min, max) {
		min = Math.ceil(min);
		max = Math.floor(max);
		return Math.floor(Math.random() * (max - min + 1)) + min;
	}

	/* getCurrentLocation(){
		return new Promise((resolve, reject) =>{
			if (navigator.geolocation) {
				navigator.geolocation.getCurrentPosition((position) => {
					if (position) {
						console.log("Latitude: " + position.coords.latitude +
							"Longitude: " + position.coords.longitude);
						this.lat = position.coords.latitude;
						this.lng = position.coords.longitude;
						return resolve(true);
					}
				},
					(error) => {
						console.log("Please allow the location", error);
						swal.fire({
							icon: 'info',
							title: 'Oops...',
							backdrop: false,
							text: 'Please allow the location.',
						});
						return resolve(false);
					});
			} else {
				alert("Geolocation is not supported by this browser.");
				return resolve(false);
			}
		})
	} */

}

@Injectable()
export class IdeateValidators {

	constructor(
		public helper: IdeateProvider
	) { }


	static email(c: FormControl) {
		const EMAIL_REGEXP = /^[_a-z0-9]+(\.[_a-z0-9]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,4})$/;

		return (c.value === '' || EMAIL_REGEXP.test(c.value)) ? null : {
			email: {
				valid: false
			}
		};
	}

	static equalTo(matchControl: string) {
		let control1: FormControl;
		let control2: FormControl;
		return ((control: FormControl) => {
			if (!control.parent) {
				return null;
			}
			if (!control1) {
				control1 = control;
				control2 = control.parent.get(matchControl) as FormControl;
				if (!control2) {
					throw new Error('equalToValidator(): Second control is not found in parent group');
				}
				control2.valueChanges.subscribe(() => {
					control1.updateValueAndValidity();
				});
			}
			if (!control2) {
				return null;
			}
			if (control2.value !== control1.value) {
				return {
					equalTo: {
						valid: false
					}
				};
			}
			return null;
		});
	}

	static slug(c: FormControl) {
		const USERNAME_REGEXP = /^[a-z0-9_-]+$/i;

		return USERNAME_REGEXP.test(c.value) ? null : {
			slug: {
				valid: false
			}
		};
	}

	static strongPassword(c: FormControl) {
		return (
			c.value === '' || (
				/^[A-Za-z0-9\d`\-=\\\/;',.~!@#$%^&*()_+{}|:"<>?]*$/.test(c.value) // consists of only these
				&& /[A-Z]/.test(c.value) // has an uppercase letter
				&& /[a-z]/.test(c.value) // has a lowercase letter
				&& /\d/.test(c.value) // has a digit
				&& /[`\-=\\\/;',.~!@#$%^&*()_+{}|:"<>?]/.test(c.value) // has a symbol
			)
		) ? null : {
			strongPassword: {
				valid: false
			}
		};
	}

	remote(apiEndPoint: string, mainParamName: string, params: any = {}) {

	// eslint-disable-next-line @typescript-eslint/no-shadow
/* 	return (formControl: AbstractControl): Promise<ValidationErrors | null> => new Promise((resolve, reject) => {
		resolve({remote: { valid: false } });
	}); */

	/* 	let debounceTimer;
		const helper = this.helper;
		return (formControl: FormControl) => new Promise((resolve) => {
				if (debounceTimer) {
					clearTimeout(debounceTimer);
				}
				params[mainParamName] = formControl.value;
				if(params[mainParamName] !== ''){
					debounceTimer = setTimeout(() => helper.makeHttpRequest(apiEndPoint, params).then((response: any) => {
							if (response.success === 1) {
								console.log(response);
								return resolve(null);
							} else {
								return resolve({
									remote: { valid: false }
								});
							}
						}).catch(() => resolve({
							remote: { valid: false }
						})), 500);
				}
			}); */
	}

	/* remote(apiEndPoint: string, mainParamName: string, params: any = {}) {
		let debounceTimer;
		const helper = this.helper;
		return (formControl: FormControl) => {
			return new Promise((resolve) => {
				if (debounceTimer) {
					clearTimeout(debounceTimer);
				}
				params[mainParamName] = formControl.value;
				debounceTimer = setTimeout(async () => {
					return await helper.makeHttpRequest(apiEndPoint, params).then((response) => {
						if (response.success === 1) {
							return resolve(null);
						} else {
							return resolve({
								'remote': { valid: false }
							});
						}
					}).catch(() => {
						return resolve({
							'remote': { valid: false }
						});
					});
				}, 500);
			});
		};
	}
 */
}
