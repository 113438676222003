import { NgModule } from '@angular/core';
import { BrowserModule, HammerModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { HttpModule } from '@angular/http';
import { IonicStorageModule } from '@ionic/storage-angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// import { DatePicker } from '@capacitor-community/date-picker';
import { CommonModule } from '@angular/common';

import { SharedModule } from './pages/shared/shared.module';
import { ModalPageModule } from './pages/shared/modal/modal.module';

import { MediaCapture } from '@awesome-cordova-plugins/media-capture/ngx';
import { S3Provider } from './providers/s3/s3';

import { FileTransfer } from '@awesome-cordova-plugins/file-transfer/ngx';
import { File } from '@awesome-cordova-plugins/file/ngx';
import { AndroidPermissions } from '@awesome-cordova-plugins/android-permissions/ngx';
import { Camera } from '@awesome-cordova-plugins/camera/ngx';
import { FilePath } from '@awesome-cordova-plugins/file-path/ngx';



import { IdeateProvider, IdeateValidators } from './providers/ideateProvider';
import { Account } from './providers/account';

/* import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha';
import { environment } from 'src/environments/environment';
 */
@NgModule({
	declarations: [AppComponent],
	entryComponents: [],
	imports: [
		HttpModule,
		FormsModule,
		ReactiveFormsModule,
		IonicStorageModule.forRoot(),
		BrowserModule,
		IonicModule.forRoot(),
		AppRoutingModule,
		HammerModule,
		CommonModule,
		// RecaptchaV3Module
	],
	providers: [
		IdeateProvider,
		Account,
		IdeateValidators,
		{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
	/* 	{
			provide: RECAPTCHA_V3_SITE_KEY,
			useValue: environment.recaptcha.siteKey,
		}, */
		Camera,
		FilePath,
		MediaCapture,
		FileTransfer,
		File,
		AndroidPermissions,
	],
	bootstrap: [AppComponent],
})
export class AppModule {}
